














































































































* {
  list-style: none;
}
.header {
  width: 100%;
  height: 90px;
  background-color: #000;
}
.site-map {
  //padding-top: 90px;
  width: 1200px;
  margin: 0 auto;
  .tab-level-1 {
    margin: 0 0 50px;
    h3 {
      height: 90px;
      line-height: 90px;
      font-size: 24px;
      font-weight: normal;
      border-bottom: 1px dashed #999;
      margin-bottom: 10px;
    }
    .tab-level-2 {
      height: 40px;
      line-height: 40px;
      a {
        color: #333;
        font-size: 14px;
        display: flex;
        align-items: center;
        span {
          font-size: 34px;
          color: red;
        }
      }
    }
  }
}
@media (max-width: 767px){
  .header{
    height: 50px;
  }
  .site-map{
    width: 100%;
  }
}
